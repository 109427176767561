import { AboutUsSlider } from "../../components/about-us-slider";
import "./about-us-text-image.scss";
import { useTranslation } from "react-i18next";

function AboutUsTextImage({ title, text }) {

  const aboutUsSlide = useTranslation("aboutUsTextImageData").t("aboutUsTextImageSlides", { returnObjects: true }).map(slide => slide)

  return (
    <section className="about-us__text__image">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-6">
            <AboutUsSlider
              aboutUsSlide={aboutUsSlide}
            />
          </div>
          <div className="col-12 col-lg-5">
            <div>
              <h1 className="section__title">{title}</h1>
              <p className="section__text">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { AboutUsTextImage };

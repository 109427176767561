import { AboutUsTextImage } from "../sections/about-us-text-image";
import { AboutUsTeam } from "../sections/about-us-team";
import { useTranslation } from "react-i18next";


function AboutUsLayout() {

  const aboutUsData = useTranslation("aboutUsTextImageData").t("aboutUsTextImageInfo", { returnObjects: true })

  return (
    <>
      <AboutUsTextImage
        title={aboutUsData.title}
        text={aboutUsData.text}
      />
      <AboutUsTeam />
    </>
  );
}

export { AboutUsLayout };

import "./buttons.scss";
import { Link } from "react-router-dom";
import lightModeIcon from "../../assets/images/light-mode.svg";
// import { useEffect, useState } from "react";
import { ArrowRight } from 'react-feather';

function OutlinePositive({ text = "#", url = "#" }) {
  return (
    <Link to={`${url}`} className="button button--positive">
      {text}
      <ArrowRight />
    </Link>
  );
}

function OutlineNegative({ text = "#", url = "#" }) {
  return (
    <Link to={`${url}`} className="button button--negative">
      {text}
      <ArrowRight />
    </Link>
  );
}

function Solid({ text = "#", url = "#" }) {
  return (
    <Link to={`${url}`} className="button button--solid">
      {text}
    </Link>
  );
}

function SolidHref({ text = "#", url = "#", target = "self", onClick }) {
  return (
    <a href={`${url}`} className="button button--solid" target={target} rel="noreferrer" onClick={onClick}>
      {text}
    </a>
  );
}

function ToggleLightDark({ toggleBodyClassName }) {
  return (
    <button
      className="toggle-button--light"
      onClick={toggleBodyClassName}
      data-testid="toggle-theme"
    >
      <img
        src={lightModeIcon}
        alt="light mode icon"
        className="toggle-light-dark" width="23" height="24"
      />
    </button>
  );
}

function CommonButton({ text = "#", url = "#" }) {
  return (
    <Link to={`${url}`} className="button button--common">
      {text}
      <ArrowRight />
    </Link>
  );
}

function BurgerButton({ clicked, handleBurgerState }) {
  return (
    <div
      role="button"
      className={`burger-button ${clicked ? 'active' : ''}`}
      onClick={handleBurgerState}
      data-testid="burger-button"
    >
      <div className="burger-item burger-top"></div>
      <div className="burger-item burger-mid"></div>
      <div className="burger-item burger-bot"></div>
    </div>
  );
}

export {
  OutlineNegative,
  OutlinePositive,
  Solid,
  ToggleLightDark,
  CommonButton,
  BurgerButton,
  SolidHref
};

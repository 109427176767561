import "./home-services.scss";
import { CardService } from "../../components/card-service";
import { OutlinePositive } from "../../components/buttons";
import { SectionTitle } from "../../components/section-title";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";


function HomeServices() {
  const homeServices = useTranslation("homeServicesData").t("services", {returnObjects: true})
  const homeServicesTitle = useTranslation("homeServicesData").t("titleAttributes", {returnObjects: true})
  const homeServicesBUttons = useTranslation("homeServicesData").t("buttonAttributes", {returnObjects: true})

  return (
    <section className="home-services">
      <div className="container">
        <SectionTitle
          classColor={homeServicesTitle.classColor}
          title={homeServicesTitle.title}
        />
        <div className="row justify-content-center">
          {homeServices?.map((item, index) => {
            let delay = 0;

            switch (index) {
              case 0:
                delay = 0;
                break;

              case 1:
                delay = 300;
                break;

              case 2:
                delay = 600;
                break;

              case 3:
                delay = 0;
                break;
              case 4:
                delay = 300;
                break;
              case 5:
                delay = 600;
                break;
              default:
                break;
            }
            return (
              <CardService
                key={uuid()}
                icon={item.icon}
                alt={item.alt}
                iconClass={item.iconClass}
                url={item.url}
                name={item.name}
                text={item.text}
                delay={delay}
              />
            );
          })}
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="text-center">
              <OutlinePositive
                text={homeServicesBUttons.text}
                url={homeServicesBUttons.url}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { HomeServices };

import "./header.scss";

import { NavLink } from "react-router-dom";

import logo from "../../assets/images/logo-light.png";

import {
  ToggleLightDark,
  BurgerButton,
  SolidHref,
} from "../../components/buttons";

import { v4 as uuid } from "uuid";

import React, { useEffect, useState } from "react";

import { MenuMobile } from "../../sections/menu-mobile";

import { useTranslation } from "react-i18next";

function Header({ propsLinks, propKit, propsButtons }) {
  const [scroll, setScroll] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100 && document.body.clientHeight > 1400);

      if (window.location.pathname === "/servicios/") {
        document.getElementsByClassName("main-header")[0].style.boxShadow =
          "none";
      }
    });
  }, []);

  // MENU MOBILE HANDLER

  const [clicked, setClicked] = useState(false);

  const displayMobileMenuToggle = () => {
    setClicked(!clicked);
  };

  // BURGER BUTTON

  const [burgerClass, setBurgerClass] = useState(false);

  const handleBurgerToggle = () => {
    setBurgerClass(!burgerClass);
  };

  const bodyThemeHandler = () => {
    // LIGHT/DARK TOGGLE

    let appTheme = document.getElementById("app");

    appTheme.classList.toggle("dark");

    appTheme.classList.toggle("light");

    localStorage.setItem("theme", appTheme.classList);
  };

  const routesCurrentES = i18n.getResourceBundle("es", "routesData");

  const routesCurrentCA = i18n.getResourceBundle("ca", "routesData");

  const routesCurrentEN = i18n.getResourceBundle("en", "routesData");

  let l = i18n.language;

  let routesCurrent = routesCurrentES;

  if (l === "es") {
    routesCurrent = routesCurrentES;
  } else if (l === "en") {
    routesCurrent = routesCurrentEN;
  } else {
    routesCurrent = routesCurrentCA;
  }

  const changeValue = () => {
    let options = document.getElementsByTagName("option");

    let currentPathname = window.location.pathname.replace(/\/+$/, "");

    let currentRouteKey = Object.keys(routesCurrent).find(
      (key) => routesCurrent[key] === currentPathname
    );

    if (currentRouteKey === undefined && l === "es") {
      currentRouteKey = "home";
    }

    if (l === "ca") {
      options[0].value = routesCurrentCA[currentRouteKey];

      options[1].value = routesCurrentES[currentRouteKey];

      options[2].value = routesCurrentEN[currentRouteKey];
    } else if (l === "en") {
      options[0].value = routesCurrentEN[currentRouteKey];

      options[1].value = routesCurrentCA[currentRouteKey];

      options[2].value = routesCurrentES[currentRouteKey];
    } else {
      options[0].value = routesCurrentES[currentRouteKey];

      options[1].value = routesCurrentEN[currentRouteKey];

      options[2].value = routesCurrentCA[currentRouteKey];
    }
  };

  return (
    <>
      <header className={`main-header ${scroll ? "sticky" : ""}`}>
        <div className="container">
          <div className="row">
            <nav className="main-header__header-wrapper">
              <div onClick={displayMobileMenuToggle} className="d-lg-none">
                <BurgerButton
                  clicked={clicked}
                  handleBurgerState={handleBurgerToggle}
                />
              </div>

              <NavLink to={propsLinks[0].url}>
                <img
                  src={logo}
                  alt="onvia logo"
                  className="header-wrapper__logo-light"
                  onClick={clicked ? displayMobileMenuToggle : function () {}}
                  width="auto"
                  height="auto"
                />
              </NavLink>

              <ul className="header-wrapper__menu-list">
                {propsLinks?.map((item) => (
                  <li key={uuid()}>
                    <NavLink to={item.url} className={`menu-list__item`} end>
                      {item.name}
                    </NavLink>
                  </li>
                ))}

                <li>
                  <a href={`${propKit.url}`} className={`menu-list__item`}>
                    {propKit.name}
                  </a>
                </li>
              </ul>

              <div className="header-wrapper__buttons-wrapper">
                <select
                  onClick={changeValue}
                  onChange={(e) => {
                    window.location.replace(e.target.value);
                  }}
                  value={i18n.language}
                  className="language-select"
                >
                  {(() => {
                    if (l === "ca") {
                      return (
                        <>
                          <option value="">CA</option>
                          <option value="">ES</option>
                          <option value="">EN</option>
                        </>
                      );
                    } else if (l === "en") {
                      return (
                        <>
                          <option value="">EN</option>
                          <option value="">CA</option>
                          <option value="">ES</option>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <option value="">ES</option>
                          <option value="">EN</option>
                          <option value="">CA</option>
                        </>
                      );
                    }
                  })()}
                </select>

                <ToggleLightDark toggleBodyClassName={bodyThemeHandler} />

                <SolidHref
                  text={propsButtons.text}
                  url={propsButtons.url}
                  target={propsButtons.target}
                />
              </div>
            </nav>
          </div>
        </div>
      </header>

      <MenuMobile
        clicked={clicked}
        displayMobileMenu={displayMobileMenuToggle}
      />
    </>
  );
}

export { Header };

import { BannerContact } from "../sections/banner-contact";
// import { HomeProjects } from "../sections/home-projects-slider";
import { HomeServices } from "../sections/home-services";
import { HomeTeam } from "../sections/home-team";
import { HomeTestimonials } from "../sections/home-testimonials";
import { HomeTextImage } from "../sections/home-text-image";
import { useTranslation } from "react-i18next";

function HomeLayout() {
  const textImageData = useTranslation("homeTextImageData").t("homeTextImage", {returnObjects: true})
  const testimonialsData = useTranslation("homeTestimonialsData").t("testimonials", {returnObjects: true}).map(item => item)
  const teamData = useTranslation("teamData").t("teamMembers", {returnObjects: true}).map(item => item)
  const teamTitle = useTranslation("teamData").t("homeTitleAttributes", {returnObjects: true})
  const teamButton = useTranslation("teamData").t("buttonAttributes", {returnObjects: true})
  const homeBanner = useTranslation("homeBannerData").t("homeBanner", { returnObjects: true })

  return (
    <>
      {" "}
      <HomeTextImage
        image={textImageData.image}
        title={textImageData.title}
        lightTitle={textImageData.lightTitle}
        blinkText={textImageData.blinkText}
        text={textImageData.text}
      />
      <HomeServices />
      <HomeTeam teamMembers={teamData} title={teamTitle} buttonProps={teamButton} />
      {/* <HomeProjects /> */}
      <HomeTestimonials testimonials={testimonialsData} />
      <BannerContact
        title={homeBanner.title}
        textButton={homeBanner.textButton}
        url={homeBanner.url}
      />
    </>
  );
}

export { HomeLayout };

import "./error404.scss"
import { OutlinePositive } from '../../components/buttons'
import { useTranslation } from "react-i18next";

const Error404 = () => {
  const errorText = useTranslation("error404Data").t("errorText", { returnObjects: true })
  const errorButton = useTranslation("error404Data").t("buttonAttributes", { returnObjects: true })


  return (
    <section className="error-404">
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <p className='error__number'>404</p>
            <h4>{errorText.text}</h4>
            <OutlinePositive text={errorButton.text} url={errorButton.url} />
          </div>
        </div>
      </div>


    </section>
  )
}

export { Error404 }

import "./footer.scss";
import logo from "../../assets/images/logo-light.png";
import { Link, NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { SolidHref } from "../../components/buttons";
import { MailTo, Tel } from "../../helpers/mail-to";
import { useTranslation } from "react-i18next";
import { Linkedin } from 'react-feather';

function Footer() {
  const footerLogo= useTranslation("footerData").t("footerLogo", { returnObjects: true })
  const footerLinks = useTranslation("footerData").t("footerLinks", { returnObjects: true })
  const footerButtonsAttributes = useTranslation("footerData").t("buttonAttributes", { returnObjects: true })

  const footerMenus = footerLinks.map((item) => item);
  const currentYear = new Date().getFullYear();

  return (
    <>
    <footer className="web__footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="d-flex flex-column h-100">
              <NavLink to={footerLogo.url}>
                <img src={logo} alt="Onvia logo" className="footer__logo" width="100" height="100" />
              </NavLink>
                <img src="https://onvia.es/img/certificates.webp" alt="Onvia certificates" className="d-none d-lg-block footer__certificates" width="100" height="100" />
            </div>
          </div>
          {footerMenus?.map((data) => {
            return (
              <div className="col-6 col-lg-2" key={uuid()}>
                <h4 className="footer__title">{data.name}</h4>
                <ul>
                  {data.links?.map((item) => {
                    return (
                      <li className="footer__item" key={uuid()}>
                        {(() => {
                          switch (item.type) {
                            case "mail":
                              return <MailTo email={item.name} subject={item.subject}>{item.name}</MailTo>;
                            case "link":
                              return <Link to={item.url}>{item.name}</Link>;
                            case "tel":
                              return <Tel tel={item.name}>{item.name}</Tel>;
                            case "ahref":
                              return <a href={item.url}>{item.name}</a>;
                            case "image":
                              return <img src={item.url} alt={item.name} className="d-block d-lg-none footer__certificates" width="100" height="100" />;
                            default:
                              break;
                          }
                        })()}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          <div className="col-6 col-lg-3 footer__cta-col">
            <div className="d-flex flex-column">
              <SolidHref text={footerButtonsAttributes.text} url={footerButtonsAttributes.url} target={footerButtonsAttributes.target} />
              <a href="https://www.linkedin.com/company/onviatech" target="_blank" rel="noopener noreferrer" className="social__icon">
                <Linkedin />
              </a>
            </div>
          </div>
          <div className="col-12 footer__copyright">
            Onvia &copy; {currentYear}
          </div>
        </div>
      </div>
    </footer>
    </>
  );
}

export { Footer };

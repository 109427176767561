import './banner-contact.scss'
import { CommonButton } from '../../components/buttons'
import linesTop from '../../assets/images/banner-lines-top.png'
import linesBottom from '../../assets/images/banner-lines-bottom.png'

function BannerContact({ title, textButton, url }) {
  return (
    <section className="banner__contact">
      <div className="container">
        <div className="row d-flex">
          <div className="col-12">
            <div className="container__banner">
              <h3 className="title__banner">{title} </h3>
              <CommonButton text={textButton} url={url} />

              <img src={linesTop} className="lines__top" alt="Lines" width="100" height="100" />
              <img src={linesBottom} className="lines__bottom" alt="Lines" width="100" height="100" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BannerContact }

import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
// TRANSLATION TOOLS
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    whitelist: ["es", "ca", "en"],
    preload: ["es", "ca", "en"],
    interpolation: { escapeValue: false },
    fallbackLng: "es",
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      caches: [],
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

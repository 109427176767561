const MailTo = ({ email, subject, ...props }) => {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}`}>{props.children}</a>
  );
};

const Tel = ({ tel, ...props }) => {
  return <a href={`tel:${tel}`}>{props.children}</a>;
};

export { MailTo, Tel };

import { SectionTitle } from "../../components/section-title";
import "./services-section.scss";
import { ServicesMenu } from "../../components/services-menu";
import { ServiceArticle } from "../../components/service-article";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const ServicesSection = () => {
  const servicesData = useTranslation("servicesData").t("servicesItems", {returnObjects: true})
  const servicesTitleAttributes = useTranslation("servicesData").t("servicesTitleAttributes", {returnObjects: true})
  const services = useTranslation("servicesData").t("services", {returnObjects: true})

  // let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const themeStored = localStorage.getItem("theme");
  let [theme, setTheme] = useState(themeStored);

   useEffect(() => {
    setTheme(() => theme === "app-wrapper dark" ? true : false)
  }, [theme])


  return (
    <>
      <section className="services__section">
        <div className="container-fluid">
          <div className="row">
            <SectionTitle title={servicesTitleAttributes.title} />
            <ServicesMenu servicesItems={servicesData} />
          </div>
        </div>

        {services?.map((service) => {

          let dataAos = "";

          service.theme === "light" 
          ? (dataAos = "fade-right")
          : (dataAos = "fade-right")         

          return (
            <ServiceArticle
              theme={service.theme}
              id={service.id}
              dataAos={dataAos}
              icon={theme ? service.iconDark : service.icon }
              name={service.name}
              iconClass={service.iconClass}
              text={service.text}
              image={service.image}
              list={service.list}
              listTitle={service.listTitle}
              relatedProjects={service.relatedProjects}
              video={service.video}
              key={uuid()}
            />
          );
        })}
      </section>
    </>
  );
};

export { ServicesSection };

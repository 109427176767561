import React from "react";
import "./section-title.scss";

const SectionTitle = ({ classColor = "", title, span }) => {
  return (
    <>
      <div className="title__wrapper">
        <h1 className={`title__section ${classColor}`} data-testid="class-color">
          {title}
          {span && <span>&nbsp;{span}</span>}
        </h1>
      </div>
    </>
  );
};

export { SectionTitle };

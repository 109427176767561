import { v4 as uuid } from "uuid";
import "./services-menu.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-scroll";

function ServicesMenu({ servicesItems }) {
  const [servicesScroll, setServicesScroll] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setServicesScroll(window.scrollY > 100);
      const servicesSectEl = document.querySelector(".services__section");
      if (servicesSectEl) {
        if (window.scrollY > 100) {
          servicesSectEl.style.paddingTop = "210px";
        } else {
          servicesSectEl.style.paddingTop = "0";
        }
      }
    });
  }, []);

  return (
    <>
      <ul className={`services__list ${servicesScroll ? "sticky" : ""}`}>
        {servicesItems?.map((item) => {
          return (
            <li className="list__item" key={uuid()}>
              <Link
                activeClass="active"
                spy={true}
                offset={window.screen.width < 600 ? -240 : -190}
                to={item.url}
              >
                {item.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export { ServicesMenu };

// import { RelatedProjects } from "../related-projects";
import "./service-article.scss";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { VideoModal } from "../modal";

function ServiceArticle({
  theme,
  id,
  dataAos,
  icon,
  name,
  iconClass,
  text,
  image,
  list,
  listTitle,
  relatedProjects,
  video
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <article
      className={`service ${theme}`}
      key={uuid()}
      id={id}
      data-aos={show === true ? "" : dataAos}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="service__title">
              <div className="title__icon">
                <img src={icon} alt={name} className={iconClass} width="70" height="70" />
              </div>
              <h2 className="title__text">{name}</h2>
            </div>
            <p className="service__text">{text}</p>

            {/* List title and list change to other column when we have projects */}
            <h3 className="list__title">{listTitle}</h3>
            <ul className="list">
              {list.map((item) => {
                return (
                  <li className="list__item" key={uuid()}>
                    {item}
                  </li>
                );
              })}
            </ul>

            {/* Activate this when we have projects */}
            {/* {(() => {
              if (theme === "light" && !video) {
                return <img src={image} alt="Service img" className="service__img" width="100" height="100"/>;
              }
              else if (theme === "light" && video) {
                return (
                  <div className="play-wrapper">
                    <img src={image} alt="Service img" className="service__img" width="100" height="100"/>
                    <button className="play-button" onClick={handleShow}></button>
                  </div>
                );
              }
            })()} */}

            {/* Activate this when we have projects */}
            {/* {(() => {
              if (theme === "dark") {
                <RelatedProjects
                  title={relatedProjects.relatedTitle}
                  projects={relatedProjects.projects}
                />
              }
            })()} */}
          </div>
          <div className="offset-lg-1 col-lg-5">
            {/* Activate this when we have projects */}
            {/* <h4 className="list__title">{listTitle}</h4>
            <ul className="list">
              {list.map((item) => {
                return (
                  <li className="list__item" key={uuid()}>
                    {item}
                  </li>
                );
              })}
            </ul> */}

            {/* Change the conditionals and remove this div when we have projects */}
            <div className="d-flex align-items-center h-100">
              {(() => {
                // if (theme === "dark" && !video) {
                if (!video) {
                  return <img src={image} alt="Service img" className="service__img" width="400" height="288" />;
                }
                // else if (theme === "dark" && video) {
                else if (video) {
                  return (
                    <div className="play-wrapper">
                      <img src={image} alt="Service img" className="service__img" width="400" height="288" />
                      <button className="play-button" onClick={handleShow}></button>
                    </div>
                  );
                }
              })()}

              {/* Activate this when we have projects */}
              {/* {(() => {
              if (theme === "light") {
                <RelatedProjects
                  title={relatedProjects.relatedTitle}
                  projects={relatedProjects.projects}
                />
              }
            })()} */}
            </div>
          </div>
        </div>
      </div>
      <VideoModal show={show} handleClose={handleClose} video={video} />
    </article>
  );
}

export { ServiceArticle };

import React, { Suspense } from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./layouts/header";
import { Footer } from "./layouts/footer";
// import { OurProjects } from "./sections/our-projects";
import { ServicesSection } from "./sections/services-section";
import { Contact } from "./layouts/contact";

import ScrollToTop from "./helpers/scroll-to-top";
import { HomeLayout } from "./layouts/home";
import { AboutUsLayout } from "./layouts/about-us";
// TRANSLATE TOOLS
import { useTranslation } from "react-i18next";
import { LegalAdvice, PrivacyPolicy, CookiesPolicy, SocialPolicy} from "./sections/legal-texts";
import { Error404 } from "./sections/error404";



function Welcome() {
  const headerNameList = useTranslation("headerData").t("headerLinks", { returnObjects: true }).map(item => item)
  const headerKitLink = useTranslation("headerData").t("kitLink", { returnObjects: true })
  const headerLinkList = useTranslation("headerData").t("buttonAttributes", { returnObjects: true })

  const homeRoute = useTranslation("routesData").t("home", { returnObjects: true })
  const aboutRoute = useTranslation("routesData").t("about", { returnObjects: true })
  const servicesRoute = useTranslation("routesData").t("services", { returnObjects: true })
  // const projectsRoute = useTranslation("routesData").t("projects", { returnObjects: true })
  const contactRoute = useTranslation("routesData").t("contact", { returnObjects: true })
  const legalAdviceRoute = useTranslation("routesData").t("legalAdvice", { returnObjects: true })
  const privacyPolicyRoute = useTranslation("routesData").t("privacyPolicy", { returnObjects: true })
  const cookiesPolicyRoute = useTranslation("routesData").t("cookiesPolicy", { returnObjects: true })
  const socialPolicyRoute = useTranslation("routesData").t("socialPolicy", { returnObjects: true })

  let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const localStorageTheme = localStorage.getItem("theme");


  return (
    <div className={localStorageTheme ? localStorageTheme : (dark ? "app-wrapper dark" : "app-wrapper light")} id="app">
      <BrowserRouter>
        <ScrollToTop />
        <Header propsLinks={headerNameList} propKit={headerKitLink} propsButtons={headerLinkList} />
        <Routes>
          <Route exact path={homeRoute} element={<HomeLayout />} />
          <Route exact path={aboutRoute} element={<AboutUsLayout />} />
          <Route
            exact path={servicesRoute}
            element={
              <>
                <ServicesSection />
              </>
            }
          />
          {/* <Route exact path={projectsRoute} element={<OurProjects />} /> */}

          {/* <Route path="/proyectos/"
            element={<OurProjects />}
          />
          <Route
            path="/proyectos/proyecto-1/"
            element={<SingleProject project={projectsData[0]} />}
          />
          +{" "}
          <Route
            path="/proyectos/proyecto-2/"
            element={<SingleProject project={projectsData[1]} />}
          /> */}
          <Route exact path={contactRoute} element={<Contact />} />
          <Route exact path={legalAdviceRoute} element={<LegalAdvice />} />
          <Route exact path={privacyPolicyRoute} element={<PrivacyPolicy />} />
          <Route exact path={cookiesPolicyRoute} element={<CookiesPolicy />} />
          <Route exact path={socialPolicyRoute} element={<SocialPolicy />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

function App() {
  return (
    //<Suspense fallback='LOADING...'>
    <Suspense>
      <Welcome />
    </Suspense>
  );
}

export { App }

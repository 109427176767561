import "./home-text-image.scss";
import linesBottom from "../../assets/images/banner-lines-bottom.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function HomeTextImage({ image, title, lightTitle, text }) {
	return (
		<section className="home-text__image">
			<div className="container">
				<div className="row flex-lg-row-reverse">
					<div className="col-12 col-lg-7">
						<div className="container__images d-flex justify-content-center justify-content-lg-end">
							<img
								src={image}
								className="main__image"
								alt="Home img"
								data-aos="fade-up-left"
								data-aos-duration="2000"
								width="400"
								height="270"
							/>
							<img
								src={linesBottom}
								className="lines__bottom"
								alt="Lines"
								width="80"
								height="100"
							/>
						</div>
					</div>
					<div className="col-12 col-lg-5">
						<div className="d-lg-flex justify-content-lg-center flex-lg-column h-100">
							<h1 className="section__title">
								{title} <span className="light__title">{lightTitle}</span>
								<span className="blink"></span>
							</h1>
							<p>{text}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export { HomeTextImage };

import "./card-service.scss";
// import { Link } from "react-router-dom";
import { ArrowRight } from 'react-feather';
import { HashLink as Link } from "react-router-hash-link";

const CardService = ({ icon, alt, iconClass, url, name, text, delay }) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = 0;
    if (document.body.clientWidth > 992) {
      yOffset = -80;
    } else {
      yOffset = -160;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }
  return (
    <div className="col-12 col-lg-4 card__col">
      <article
        className="card__wrapper"
        data-aos="fade-in"
        data-aos-delay={delay}
        data-aos-duration="400"
        data-testid="delay"
      >
        <Link to={`${url}`} className="card__link" scroll={scrollWithOffset}>
          <div className="card__service">
            <div className="icon__service" data-testid="icon-service">
              <img src={icon} alt={alt} className={iconClass} width="100" height="100" />
            </div>
            <h3 className="title__service">{name}</h3>
            <p className="text__service">{text}</p>
            <div className="link__service">
              <ArrowRight />
            </div>
          </div>
        </Link>
      </article>
    </div>
  );
};

export { CardService };

import "./modal.scss";
import Modal from 'react-bootstrap/Modal';
import { X } from 'react-feather';

const VideoModal = ({ show, handleClose, video }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header>
          <button type="button" className="btn-close" onClick={handleClose}><X /></button>
        </Modal.Header>
        <Modal.Body>
          <iframe src={video} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { VideoModal };

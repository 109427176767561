import "./card-team.scss";

const CardTeam = ({ image, name, position, effect, delay }) => {
  return (
    <>
      <article
        className="team__card"
        data-aos={effect}
        data-aos-duration="600"
        data-aos-delay={delay}
      >
        <aside className="card__aside">
          <figure className="card__figure">
            <img src={image} alt="Team member img" className="card__img" width="184" height="210" />
          </figure>
        </aside>
        <header className="card__header">
          <h3 className="card__title">{name}</h3>
          <p className="card__subtitle">{position}</p>
        </header>
      </article>
    </>
  );
};

export { CardTeam };

import "./legal-texts.scss"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function LegalAdvice() {

  const legalAdvice = useTranslation("legalAdviceData").t("legalAdvice", { returnObjects: true })
  const parse = require('html-react-parser');
  return (

    <section className="legal-advice">
      <div className="container">
        <div className="row">

          <h2 className="title__section">{legalAdvice.title}</h2>
          {parse(legalAdvice.text)}

        </div>
      </div>
    </section>

  )
}

function PrivacyPolicy() {
  const privacyPolicy = useTranslation("privacyPolicyData").t("privacyPolicy", { returnObjects: true })
  const parse = require('html-react-parser');
  return (

    <section className="legal-advice">
      <div className="container">
        <div className="row">

          <h2 className="title__section">{privacyPolicy.title}</h2>
          {parse(privacyPolicy.text)}
        </div>
      </div>
    </section>

  )
}

function CookiesPolicy() {
  const cookiesPolicy = useTranslation("cookiesPolicyData").t("cookiesPolicy", {returnObjects: true})
  const { i18n } = useTranslation();
  useEffect(() => {
    let l = i18n.language;
    const script = document.createElement("script")
    const row = document.getElementsByClassName("cookie__content")[0]

    script.src = "https://consent.cookiebot.com/46960f3a-6624-4dbc-b33a-1605ae8880d6/cd.js"
    script.async = true
    script.type = "text/javascript"
    script.id = "CookieDeclaration"
    script.setAttribute("data-culture", l)

    row.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      row.removeChild(script)
    }
  });
  return (
    <>
    <section className="legal-advice">
      <div className="container">
        <div className="row">
          <h2 className="title__section">{cookiesPolicy.title}</h2>
          <div className="cookie__content">

          </div>
        </div>
      </div>
    </section>
    </>

  )
}

function SocialPolicy() {
  const socialPolicy = useTranslation("socialPolicyData").t("socialPolicy", { returnObjects: true })
  const parse = require('html-react-parser');
  return (

    <section className="legal-advice">
      <div className="container">
        <div className="row">

          <h2 className="title__section">{socialPolicy.title}</h2>
          {parse(socialPolicy.text)}
        </div>
      </div>
    </section>

  )
}

export { LegalAdvice, PrivacyPolicy, CookiesPolicy, SocialPolicy }

import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuid } from "uuid";
import "./about-us-slider.scss"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination } from "swiper";

function AboutUsSlider({ aboutUsSlide }) {

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="about-us__slider"
        data-aos="fade-up-right"
        data-aos-duration="2000"
      >
        {aboutUsSlide?.map((slide) => {
          return (<SwiperSlide key={uuid()}>
            <img src={slide.image} className="main__image" alt={slide.alt} width="400" height="270" />
          </SwiperSlide>)
        })}
      </Swiper>
    </>
  )
}

export { AboutUsSlider }
import "./contact.scss";
import { SectionTitle } from "../../components/section-title";
import { useTranslation } from "react-i18next";

function Contact() {

  const contactInfo = useTranslation("contactData").t("contactInfo", { returnObjects: true })

  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <SectionTitle title={contactInfo.title} />
          <div className="col-12 col-lg-5">
            <p className="paragraph__light">{contactInfo.text}</p>
            <div data-aos="fade-in" data-aos-delay="400">
              <h2 className="subtitle">{contactInfo.contactTitle}</h2>
              <p className="paragraph__light paragraph__light--margin">
                {contactInfo.phone}
              </p>
              <p className="paragraph__light">{contactInfo.mail}</p>

              <h2 className="subtitle">{contactInfo.schedule.title}</h2>
              <p className="paragraph__light paragraph__light--margin">
                {contactInfo.schedule.scheduleDetails[0]}
              </p>
              <p className="paragraph__light">
                {" "}
                {contactInfo.schedule.scheduleDetails[1]}
              </p>

              <h2 className="subtitle">{contactInfo.adressTitle}</h2>
              <p className="paragraph__light paragraph__light--margin">
                {contactInfo.street}
              </p>
              <p className="paragraph__light paragraph__light--margin">
                {contactInfo.city}
              </p>

              <p className="paragraph__light paragraph__light--margin">
              <a
                href={contactInfo.linkTo.url}
                className="link__maps"
                target={contactInfo.linkTo.target}
              >
                {contactInfo.linkTo.text}
              </a>
              </p>

            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-1">
            <iframe
              title="Onvia map"
              className="container__map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11933.676088111712!2d2.2907728!3d41.6034498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb8f50d973aad25cd!2sOnvia!5e0!3m2!1sca!2ses!4v1653974694857!5m2!1sca!2ses"
              width="600"
              height="450"
              // style="border:0;"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export { Contact };

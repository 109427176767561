import "./about-us-team.scss";
import { CardTeam } from "../../components/card-team";
import { SectionTitle } from "../../components/section-title";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

const AboutUsTeam = () => {
  const teamData = useTranslation("teamData").t("teamMembers", {returnObjects: true})
  const teamSectionTitle = useTranslation("teamData").t("aboutUsTitleAttributes", {returnObjects: true})

  return (
    <section className="about-us__team" data-testid="about-us">
      <div className="container">
        <SectionTitle title={teamSectionTitle.title} />
        <div className="row">
          {teamData?.map((member) => {
            return (
              <div className="col-6 col-lg-3 team__wrapper" key={uuid()}>
                <CardTeam
                  image={member.image}
                  name={member.name}
                  position={member.position}
                  effect="fade-down"
                  delay="300"
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export { AboutUsTeam };

import "./home-testimonials.scss";
import { SectionTitle } from "../../components/section-title";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper";

function HomeTestimonials({ testimonials }) {
  const titleAttributes = useTranslation("homeTestimonialsData").t("titleAttributes", { returnObjects: true })

  return (
    <section className="home__testimonials">
      <div className="container">
        <div className="row justify-content-between">
          <SectionTitle title={titleAttributes.title} />

          <Swiper
            loop={true}
            loopFillGroupWithBlank={true}
            breakpoints={{
              // when window width is >= 640px
              992: {
                width: 992,
                slidesPerView: 5,
              },
              // when window width is >= 768px
              320: {
                width: 320,
                slidesPerView: 2,
              },
            }}
            spaceBetween={40}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
            data-aos-duration="2000"
          >
            {testimonials?.map((item) => {
              return (<SwiperSlide key={uuid()}>
                <a href={`${item.url}`} target={item.target}>
                  <img
                    src={item.image}
                    className="testimonial__image"
                    alt={item.alt} width="100" height="100"
                  />
                </a>
              </SwiperSlide>)
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export { HomeTestimonials };

import "./home-team.scss";
import { SectionTitle } from "../../components/section-title";
// import {
//   homeTitleAttributes,
//   buttonAttributes,
// } from "../../constants/teamData";
import { v4 as uuid } from "uuid";
import { CardTeam } from "../../components/card-team";
import { CommonButton } from "../../components/buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";

const HomeTeam = ({ teamMembers, title, buttonProps }) => {
  // Shuffle array
  let selectedMembers;
  if(teamMembers) {
    let randomOrder = teamMembers.sort(() => 0.4 - Math.random());
    selectedMembers = randomOrder.slice(-4);
  }
  return (
    <>
      <section className="home__team">
        <div className="container">
          <div className="row">
            <SectionTitle
              classColor="common"
              title={title.title}
              span={title.span}
            />
            {selectedMembers?.map((member, index) => {
              let effect = "";
              let delay = 0;

              switch (index) {
                case 0:
                  effect = "fade-up";
                  delay = 0;
                  break;

                case 1:
                  effect = "fade-down";
                  delay = 200;
                  break;

                case 2:
                  effect = "fade-up";
                  delay = 400;
                  break;

                case 3:
                  effect = "fade-down";
                  delay = 600;
                  break;

                default:
                  break;
              }

              return (
                <div className="col-12 col-lg-3 team__wrapper" key={uuid()}>
                  <CardTeam
                    image={member.image}
                    name={member.name}
                    position={member.position}
                    effect={effect}
                    delay={delay}
                  ></CardTeam>
                </div>
              );
            })}
            <Swiper
              pagination={true}
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={1.5}
            >
              {selectedMembers?.map((member) => {
                return (
                  <SwiperSlide key={uuid()}>
                    <CardTeam
                      image={member.image}
                      name={member.name}
                      position={member.position}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="d-flex justify-content-center">
              <CommonButton
                text={buttonProps.text}
                url={buttonProps.url}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { HomeTeam };

import "./menu-mobile.scss";
import { SolidHref } from "../../components/buttons";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";



function MenuMobile({ clicked, displayMobileMenu }) {
  const headerLinks = useTranslation("headerData").t("headerLinks", { returnObjects: true })
  const kitLink = useTranslation("headerData").t("kitLink", { returnObjects: true })
  const headerButtonAttributes = useTranslation("headerData").t("buttonAttributes", { returnObjects: true })
  if (clicked) {
    document.body.classList.add('no-scroll');
  }
  else {
    document.body.classList.remove('no-scroll');
  }
  return (
    // ADD CLASS 'active' TO SHOW MENU
    <div
      className={`menu-mobile ${clicked ? "active" : ""}`}
    >
      <div className="container">
        <ul className="menu-mobile__link-list">
          {headerLinks?.map((link) => {
            return (
              <li className="link-list__item" key={uuid()}>
                <NavLink
                  to={link.url}
                  activeclassname="active"
                  className={`menu-list__item`}
                  onClick={displayMobileMenu}
                >
                  {link.name}
                </NavLink>

              </li>
            );
          })}
          <li>
            <a href={`${kitLink.url}`} className={`menu-list__item`}>
              {kitLink.name}
            </a>
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-center">
        <SolidHref
          text={headerButtonAttributes.text}
          url={headerButtonAttributes.url}
          target={headerButtonAttributes.target}
          onClick={displayMobileMenu}>
        </SolidHref>
      </div>
    </div>
  );
}

export { MenuMobile };
